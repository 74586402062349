<template>
    <div class="logisticsAntLine">
        <div class="close-btn el-icon-close" @click="handleClose"></div>
        <div class="header-con">
            <!-- <div class="search-section">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="排序">
                        <el-select v-model="">
                            <el-option label="任务单号" value="shanghai"></el-option>
                            <el-option label="任务单号2" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排列">
                        <el-select>
                            <el-option label="两列" value="shanghai"></el-option>
                            <el-option label="一列" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div> -->
            <div class="legend-section">
                <div class="color-item">
                    <span class="color blue"></span>
                    <span>运输中</span>
                </div>
                <div class="color-item">
                    <span class="color orange"></span>
                    <span>返场中</span>
                </div>
                <div class="color-item">
                    <span class="color red"></span>
                    <span>停止</span>
                </div>
                <div class="color-item">
                    <span class="color purple"></span>
                    <span>卸料</span>
                </div>
                <div class="color-item">
                    <span class="color green"></span>
                    <span>工地（未卸料）</span>
                </div>
                <div class="color-item" @click="pushData">
                    <span class="color gray"></span>
                    <span>无信号</span>
                </div>
            </div>
        </div>
        <div class="main-con">
            <div class="item" v-for="(item, index) in workOrderList" :key="item.task_number">
                <h4>
                    <span>{{ index+1 }}</span>
                    <span>{{ item.task_number }}</span>
                    <span>【</span>
                    <span class="project_name" :title="item.project_name">{{ item.project_name }}</span>
                    <span>】</span>
                    <span>【{{ item.place }}】【{{ item.strength_grade }}】【{{ item.haul_distance_show }}km】</span>
                </h4>
                <div class="line-con">
                    <div class="line-item line">
                        <div class="left"></div>
                        <div class="line-center">
                            <i></i>
                            <div class="distance start">
                                0km
                            </div>
                            <div class="distance middle">
                                {{ item.half_haul_distance }}km
                            </div>
                            <div class="distance end">
                                {{ item.haul_distance_show }}km
                            </div>
                            <template
                                v-for="(car, vindex) in item.cars"
                            >
                                <div
                                    :class="getClass(car)"
                                    :style="{left:computeDis(item,car)+'%', marginLeft: parseInt(computeDis(item,car))!=0?'-0.14rem':'0.03rem'}"
                                    :key="`${item.project_code + vindex}`"
                                    v-if="car.orientation===0&&(car.status===1||car.status===4||car.status===5||car.status===6)"
                                >
                                    {{ car.plate_small_number }}
                                </div>
                                <div
                                    :class="getClass(car)"
                                    :style="{right:computeDis(item,car)+'%', marginRight: parseInt(computeDis(item,car))!=0?'-0.14rem':'0.03rem'}"
                                    :key="`${item.project_code + vindex}`"
                                    v-if="car.orientation===1&&(car.status===1||car.status===4||car.status===5||car.status===6)"
                                >
                                    {{ car.plate_small_number }}
                                </div>
                            </template>
                        </div>
                        <div class="right"></div>
                    </div>
                    <div class="line-item car">
                        <div class="car-row">
                            <template v-for="(car, carindex) in item.cars">
                                <span
                                    :class="getClass(car)"
                                    v-if="car.status===2||car.status===3"
                                    :key="`${car.plate_number}${carindex}`"
                                >{{ car.plate_small_number }}</span>
                            </template>
                        </div>
                    </div>
                    <div class="line-item statistics">
                        <div class="el_left">
                            <div>
                                <label>计划量:</label>
                                <span>{{ item.plan_quantity }}</span>
                            </div>
                            <div>
                                <label>完成量:</label>
                                <span>{{ item.grand_total_quantity }}</span>
                            </div>
                            <div>
                                <label>车次:</label>
                                <span>{{ item.grand_total_vehicle }}</span>
                            </div>
                        </div>
                        <div class="el_left">
                            <div class="box">
                                <div class="inner" :style="{height: getFinishInner(item)}"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { eventBus } from '@/common/eventBus.ts';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            workOrderList: [],
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取正在生产的任务单
        getProductionList() {
            this.$axios
                .get('/interfaceApi/production/producttask/get_production_list?stationCode=' + this.extr.stationCode)
                .then(async res => {
                    if (res && res.length>0) {
                        this.workOrderList = res;
                        let taskNumbers = '';
                        res.map((item, index) => {
                            taskNumbers += item.task_number + ',';
                            // eslint-disable-next-line radix
                            item.haul_distance_show = (item.haul_distance / 1000).toFixed(2);
                            item.half_haul_distance = (item.haul_distance / 2 / 1000).toFixed(2);
                            if (item.project_type === '103-FJ0') {
                                item.project_name = item.project_name + '| ' + item.monomer_name;
                            }
                            if (item.product_status!==2) {
                                res.splice(index,1);
                            }
                        });
                        const cars = await this.getTaskCars(taskNumbers);
                        if (cars) {
                            const map = {};
                            for (let i = 0; i < cars.length; i++) {
                                const ai = cars[i];
                                if (!map[ai.task_number]) {
                                    map[ai.task_number] = [];
                                    // eslint-disable-next-line max-depth
                                    if (ai.status === 0 || ai.status === 10) {
                                        // map[ai.task_number] = [];
                                    } else {
                                        map[ai.task_number].push(ai);
                                    }

                                } else {
                                    // eslint-disable-next-line max-depth
                                    if (ai.status === 0 || ai.status === 10) {
                                        // map[ai.task_number] = [];
                                    } else {
                                        map[ai.task_number].push(ai);
                                    }
                                }
                            }
                            this.workOrderList = this.workOrderList.map(item => {
                                if (map[item.task_number]) {
                                    item.cars = map[item.task_number];
                                } else {
                                    item.cars = [];
                                }
                                return item;
                            });
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取运输中车辆
        getTaskCars(taskNumbers) {
            return this.$axios.get('/interfaceApi/production/producttask/get_task_cars?task_number=' + taskNumbers);
        },
        // 车辆状态
        getClass(data) {
            let str = 'car';
            if (data.orientation === 0) {
                str += ' to-car';
            } else {
                str += ' back-car';
            }
            switch (data.status) {
                case 1:
                    str += ' trans-car';
                    break;
                case 2:
                    str += ' arrive-car';
                    break;
                case 3:
                    str += ' discharge-car';
                    break;
                case 4:
                    str += ' return-car';
                    break;
                case 5:
                    str += ' stop-car';
                    break;
                case 10:
                    str += ' finish-car';
                    break;
                default:
            }
            return str;
        },
        // 计算车辆位置
        computeDis(item, car) {
            let dis = 0;
            if (car.orientation === 0) {
                if (car.to_distance <= item.haul_distance) {
                    dis = (car.to_distance / item.haul_distance * 100).toFixed(2);
                } else {
                    dis = 95;
                }
            } else if (car.orientation === 1) {
                if (car.back_distance <= item.haul_distance) {
                    dis = (car.back_distance / item.haul_distance * 100).toFixed(2);
                } else {
                    dis = 5;
                }
            }
            return dis;
        },
        // 完成率
        getFinishInner(data) {
            let finishRate = 0;
            if (data.plan_quantity) {
                let rate = (data.grand_total_quantity / data.plan_quantity * 100).toFixed(2);
                if (rate>100) {
                    rate = 100;
                }
                finishRate = rate + '%';
            }
            return finishRate;
        },
        // 关闭
        handleClose() {
            window.hideToast();
        },
        // 任务单改变
        async taskLineChange(data) {
            // 生产状态 0 未排 1 待生产2 生产中 3 已完成 4 暂停 5 已关闭 6 已取消
            // 只更新生产中的
            const res = this.workOrderList.filter(item => item.task_number === data.task_number);
            if (data.station === this.extr.stationCode) {
                if (data.product_status === 2) {
                    if (res.length > 0) {
                        this.workOrderList = this.workOrderList.map(item => {
                            if (data.task_number === item.task_number) {
                                const cars = JSON.parse(JSON.stringify(item)).cars;
                                item = data;
                                item.haul_distance_show = (data.haul_distance / 1000).toFixed(2);
                                item.half_haul_distance = (data.haul_distance / 2 / 1000).toFixed(2);
                                item.cars = cars;
                            }
                            return item;
                        });
                    } else {
                        data.haul_distance_show = (data.haul_distance / 1000).toFixed(2);
                        data.half_haul_distance = (data.haul_distance / 2 / 1000).toFixed(2);
                        const cars = await this.getTaskCars(data.task_number);
                        if (cars) {
                            data.cars = cars;
                            this.workOrderList.push(data);
                        } else {
                            data.cars = [];
                            this.workOrderList.push(data);
                        }
                    }

                } else {
                    this.workOrderList.map((item, index) => {
                        if (data.task_number === item.task_number) {
                            this.workOrderList.splice(index, 1);
                        }
                    });
                }
            }
        },
        // 车辆状态改变
        mapCarChange(data) {
            // 0 默认、1运输中、2到达 3 卸料、4 返场、 5 停止、6 未知、10 完成
            let findIndex = -1;
            this.workOrderList.map(item => {
                item.cars.map((v,vIndex) => {
                    if (v.plate_number === data.plate_number) {
                        item.cars.splice(vIndex,1);
                    }
                });
                return item;
            });
            this.workOrderList = this.workOrderList.map(item => {
                if (item.task_number === data.task_number) {
                    if (data.status === 0 || data.status === 10) {
                        
                    } else {
                        item.cars.push(data);
                    } 
                }
                return item;
            });
        },
        pushData() {
            const  data = {
                task_number: '【SP】PT202006-021',
                plate_number: "京A00002",
                plate_small_number: "002",
                to_distance: 102.96,
                back_distance: 370.27,
                orientation: 1,
                status: 4,
            };
            this.mapCarChange(data);
        },
    },
    created() {},
    mounted() {
        this.getProductionList();
        eventBus.$on('TaskLineChangeMsg', data => {
            this.taskLineChange(data);
        });
        eventBus.$on('MapCarChangeMsg', data => {
            this.mapCarChange(data);
        });
    },
    beforeDestroy() {
        eventBus.$off('TaskLineChangeMsg');
        eventBus.$off('MapCarChangeMsg');
    },
};
</script>
<style lang="stylus">
.logisticsAntLine
    height 100%
    position relative
    .close-btn
        border: none;
        background: none;
        line-height: 0.6rem;
        width: 0.6rem !important;
        height: 0.6rem !important;
        font-size: 0.28rem;
        text-align: center;
        color: #000;
        position: absolute;
        top: 0px;
        right: 0;
        color: #999;
        z-index: 12;
        cursor: pointer;
    .header-con
        height .6rem
        background #F8F8F8
        display flex
        align-items center
        padding-left .2rem
        justify-content space-between
        .el-form-item
            margin-bottom 0
        .legend-section
            display flex
            padding-right .8rem
            .color-item
                font-size .14rem
                display flex
                align-items center
                margin-right .2rem
                .color
                    width .25rem
                    height .14rem
                    display inline-block
                    margin-right .05rem
                    border-radius .03rem
                .blue
                    background #0048B8
                .orange
                    background #FF7E00
                .red
                    background #D6010F
                .purple
                    background #9152D8
                .green
                    background #20AD52
                .gray
                    background #C8C8C8
    .main-con
        height calc(100% - .6rem)
        overflow-y auto
        padding .2rem
        .item
            float left
            width 50%
            margin-bottom .3rem
            h4
                font-size .14rem
                overflow hidden
                span
                    float left
                .project_name
                    max-width 2rem
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    display inline-block
            .line-con
                margin-top .1rem
                .line-item
                    float left
                .car
                    .car-row
                        overflow hidden
                        max-height: .42rem;
                        span
                            width .28rem
                            height .16rem
                            text-align center
                            line-height .16rem
                            display inline-block
                            color #ffffff
                            font-size .12rem
                            border-radius .03rem
                            float left
                            margin .02rem
                        .trans-car
                            background #0048B8
                        .stop-car
                            background #D6010F
                        .discharge-car
                            background #7B36C9
                        .return-car
                            background #FF7E00
                        .arrive-car
                            background #20AD52
                .statistics
                    font-size .12rem
                    .box
                        margin-left .05rem
                        width .08rem
                        height .46rem
                        border 1px solid rgba(215,215,215,1)
                        position relative
                        .inner
                            position absolute
                            bottom 0
                            width 100%
                            background:linear-gradient(0deg,rgba(60,183,104,1),rgba(114,208,116,1));
                .line
                    width calc(100% - 2.1rem)
                    position relative
                    height .32rem
                    .left
                        width .32rem
                        height .32rem
                        background url(./../images/cz.png) no-repeat
                        background-size 100% 100%
                        position absolute
                        top 0
                        left 0
                    .line-center
                        width calc(100% - .46rem)
                        height .32rem
                        position absolute
                        left .22rem
                        top 0
                        font-size .12rem
                        i
                            position absolute
                            left 0
                            top 0.26rem
                            width 100%
                            border-bottom 1px solid #CACACA
                        .distance
                            position absolute
                            top: 0.42rem;
                        .start
                            left -.2rem
                        .middle
                            left 50%
                            margin-left -.2rem
                            &:before
                                position absolute
                                width .11rem
                                height .06rem
                                top -.08rem
                                left 50%
                                margin-left -0.06rem
                                content ' '
                                background url(./../images/triangle.png) no-repeat
                                background-size 100% 100%
                        .end
                            right -.2rem
                        .car
                            position absolute
                            width .28rem
                            height .16rem
                            border-radius .03rem
                            color #fff
                            line-height .16rem
                            text-align center
                        .to-car
                            top .1rem
                        .back-car
                            top .26rem
                        .trans-car
                            background #0048B8
                        .stop-car
                            background #D6010F
                        .discharge-car
                            background #7B36C9
                        .return-car
                            background #FF7E00
                        .arrive-car
                            background #20AD52
                    .right
                        position absolute
                        right 0
                        top 0
                        width .32rem
                        height .32rem
                        background url(./../images/flag.png) no-repeat
                        background-size 100% 100%
</style>